import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  
  {
    path: '',
    redirectTo: 'autenticacao',
    pathMatch: 'full'
  },
  {
    path: 'autenticacao',
    loadChildren: () => import('./autenticacao/autenticacao.module').then( m => m.AutenticacaoPageModule)
  },
  {
    path: 'identidade-frente',
    loadChildren: () => import('./identidade-frente/identidade-frente.module').then( m => m.IdentidadeFrentePageModule)
  },
  {
    path: 'identidade-verso',
    loadChildren: () => import('./identidade-verso/identidade-verso.module').then( m => m.IdentidadeVersoPageModule)
  },
  {
    path: 'identidade-selfie',
    loadChildren: () => import('./identidade-selfie/identidade-selfie.module').then( m => m.IdentidadeSelfiePageModule)
  },
  {
    path: 'aceitar-termos',
    loadChildren: () => import('./aceitar-termos/aceitar-termos.module').then( m => m.AceitarTermosPageModule)
  },
  {
    path: 'inicio',
    loadChildren: () => import('./inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'add-titular-dependentes',
    loadChildren: () => import('./add-titular-dependentes/add-titular-dependentes.module').then( m => m.AddTitularDependentesPageModule)
  },
  {
    path: 'atendimento',
    loadChildren: () => import('./atendimento/atendimento.module').then(m => m.AtendimentoPageModule)  
  },
  {
    path: 'titular-dependentes',
    loadChildren: () => import('./titular-dependentes/titular-dependentes.module').then(m => m.TitularDependentesPageModule)  
  },
  {
    path: 'como-usar',
    loadChildren: () => import('./como-usar/como-usar.module').then(m => m.ComoUsarPageModule)  
  },
  {
    path: 'cadastro-pets',
    loadChildren: () => import('./cadastros/cadastro-pets/cadastro-pets.module').then( m => m.CadastroPetsPageModule)
  },
  {
    path: 'cadastro-filhos',
    loadChildren: () => import('./cadastros/cadastro-filhos/cadastro-filhos.module').then( m => m.CadastroFilhosPageModule)
  },
  {
    path: 'cadastro-conjuge',
    loadChildren: () => import('./cadastros/cadastro-conjuge/cadastro-conjuge.module').then( m => m.CadastroConjugePageModule)
  },
  {
    path: 'cadastro-titular',
    loadChildren: () => import('./cadastros/cadastro-titular/cadastro-titular.module').then( m => m.CadastroTitularPageModule)
  },  {
    path: 'trocar-local',
    loadChildren: () => import('./trocar-local/trocar-local.module').then( m => m.TrocarLocalPageModule)
  },
  {
    path: 'agendar-medico',
    loadChildren: () => import('./agendar-medico/agendar-medico.module').then( m => m.AgendarMedicoPageModule)
  },
  {
    path: 'exames-medicos',
    loadChildren: () => import('./exames-medicos/exames-medicos.module').then( m => m.ExamesMedicosPageModule)
  },
  {
    path: 'atendimento-pet',
    loadChildren: () => import('./atendimento-pet/atendimento-pet.module').then( m => m.AtendimentoPetPageModule)
  },
  {
    path: 'agendar-dentista',
    loadChildren: () => import('./agendar-dentista/agendar-dentista.module').then( m => m.AgendarDentistaPageModule)
  },
  {
    path: 'agendar-psicologo',
    loadChildren: () => import('./agendar-psicologo/agendar-psicologo.module').then( m => m.AgendarPsicologoPageModule)
  },
  {
    path: 'beneficios-opcionais',
    loadChildren: () => import('./beneficios-opcionais/beneficios-opcionais.module').then( m => m.BeneficiosOpcionaisPageModule)
  },
  {
    path: 'quadro-avisos',
    loadChildren: () => import('./quadro-avisos/quadro-avisos.module').then( m => m.QuadroAvisosPageModule)
  },
  {
    path: 'medico-detalhes',
    loadChildren: () => import('./detalhes/medico-detalhes/medico-detalhes.module').then( m => m.MedicoDetalhesPageModule)
  },
  {
    path: 'locais-exames',
    loadChildren: () => import('./locais-exames/locais-exames.module').then( m => m.LocaisExamesPageModule)
  },
  {
    path: 'agendar-pet',
    loadChildren: () => import('./agendar-pet/agendar-pet.module').then( m => m.AgendarPetPageModule)
  },
  {
    path: 'dentista-detalhes',
    loadChildren: () => import('./detalhes/dentista-detalhes/dentista-detalhes.module').then( m => m.DentistaDetalhesPageModule)
  },
  {
    path: 'psicologo-detalhes',
    loadChildren: () => import('./detalhes/psicologo-detalhes/psicologo-detalhes.module').then( m => m.PsicologoDetalhesPageModule)
  },
  {
    path: 'auxilio-seguro',
    loadChildren: () => import('./auxilio-seguro/auxilio-seguro.module').then( m => m.AuxilioSeguroPageModule)
  },
  {
    path: 'plano-odontologico',
    loadChildren: () => import('./plano-odontologico/plano-odontologico.module').then( m => m.PlanoOdontologicoPageModule)
  },
  {
    path: 'clube-descontos',
    loadChildren: () => import('./clube-descontos/clube-descontos.module').then( m => m.ClubeDescontosPageModule)
  },
  {
    path: 'quem-somos',
    loadChildren: () => import('./quem-somos/quem-somos.module').then( m => m.QuemSomosPageModule)
  },
  {
    path: 'meu-contrato',
    loadChildren: () => import('./meu-contrato/meu-contrato.module').then( m => m.MeuContratoPageModule)
  },
  {
    path: 'pagamentos-via',
    loadChildren: () => import('./pagamentos-via/pagamentos-via.module').then( m => m.PagamentosViaPageModule)
  },
  {
    path: 'produtos-serv-pet',
    loadChildren: () => import('./produtos-serv-pet/produtos-serv-pet.module').then( m => m.ProdutosServPetPageModule)
  },
  {
    path: 'outras-especialidades',
    loadChildren: () => import('./outras-especialidades/outras-especialidades.module').then( m => m.OutrasEspecialidadesPageModule)
  },
  {
    path: 'paguemenos',
    loadChildren: () => import('./paguemenos/paguemenos.module').then( m => m.PaguemenosPageModule)
  },
  {
    path: 'mensagens',
    loadChildren: () => import('./mensagens/mensagens.module').then( m => m.MensagensPageModule)
  },
  {
    path: 'meu-cartao-digital',
    loadChildren: () => import('./meu-cartao-digital/meu-cartao-digital.module').then( m => m.MeuCartaoDigitalPageModule)
  },



  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
